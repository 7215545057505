<template>
  <div class="py-16 text-center bg-blue-300 px-5">
    <p class="text-white font-bold text-3xl">
      加藤史帆さんへ<br />
      ファンからのメッセージ
    </p>
    <p class="mt-10 text-white text-xs">
      先日、<RouterLink to="/graduation/messages/form" class="underline cursor-pointer">「加藤史帆さん卒業記念企画」</RouterLink>の応援広告に<br />
      掲載させていただくメッセージを、<br />
      本サイトより募集させていただきました。<br />
      最終的に、180名のファンの方々から<br />
      素敵なメッセージをいただきました！💌<br />
      ご参加いただいた皆さま、誠にありがとうございました！<br />
      <br />
      本ページでは、サイト掲載の許可をいただいた方々の<br />
      メッセージのみを掲載しております。<br />
      ぜひご覧ください！
    </p>
  </div>

  <div
    ref="scrollAreaRef"
    v-for="(gm, index) in graduationMessages"
    :key="index"
    class="mt-5 mx-4 py-6 px-4 bg-white rounded-md shadow-sm text-center"
  >
    <div class="text-xs">{{ gm.message }}</div>
  </div>
  <div
    v-if="hasNextPage"
    ref="observerRef"
    class="text-center my-10 text-gray-300"
  >
    Loading...
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useGraduationMessagesStore } from "@/stores/graduationMessagesStore";

const graduationMessagesStore = useGraduationMessagesStore();

const scrollAreaRef = ref<HTMLDivElement | null>(null);
const observerRef = ref<HTMLDivElement | null>(null);
const isFetching = ref(false);

const graduationMessages = computed(
  () => graduationMessagesStore.getGraduationMessages
);
const hasNextPage = computed(() => graduationMessagesStore.hasNextPage());

const fetchNextPage = async () => {
  if (isFetching.value) return;

  isFetching.value = true;
  try {
    await graduationMessagesStore.requestFetchList(
      graduationMessagesStore.getListCurrentPage + 1
    );
  } finally {
    isFetching.value = false;
  }
};

onMounted(() => {
  // 初回のデータ取得
  graduationMessagesStore.requestFetchList(1);

  // IntersectionObserverの設定
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && hasNextPage.value) {
          fetchNextPage();
        }
      });
    },
    {
      root: null,
      threshold: 0.5, // 0.0 ~ 1.0 : 発火させる表示領域の割合
    }
  );

  if (observerRef.value) {
    observer.observe(observerRef.value);
  }

  onUnmounted(() => {
    observer.disconnect();
  });
});
</script>
